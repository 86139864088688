import React, { useEffect, useState } from "react";
import logo2 from "./BCLogoWhite.png";

function Dashboard() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        `https://su-ledger-backend.herokuapp.com/data?page=${page}&search=${search}`
        // `http://localhost:4010/data?page=${page}&search=${search}`
      );
      const jsonData = await response.json();
      setData(jsonData.data);
      setTotalPages(jsonData.totalPages);
    };
    fetchData();
  }, [page, search]);

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setPage(1);
  };

  const handlePrevPageClick = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextPageClick = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  return (
    <div>
      <div className="logo-div">
        <img src={logo2} className="App-logo" alt="logo" />
      </div>
      <h1 className="hd-title">Van Winkle x Giving Tuesday - Entrants</h1>
      <div className="stats-div">
        <div className="stat">
          <div className="stat-value">628</div>
          <div className="stat-label">Total Tickets Entered</div>
        </div>
        <div className="stat">
          <div className="stat-value">39</div>
          <div className="stat-label"># of States Represented</div>
        </div>
        <div className="stat">
          <div className="stat-value">$26,234</div>
          <div className="stat-label">Total $ Raised For Alzheimer's</div>
        </div>
      </div>

      <div className="search-table">
        <input
          type="text"
          value={search}
          onChange={handleSearchChange}
          placeholder="Search By Name..."
        />
      </div>
      <div className="table-div">
        <table>
          <thead>
            <tr>
              <th>Ticket #</th>
              <th>Name</th>
              <th>State</th>
              <th>Tickets Purchased</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>{item.Name}</td>
                <td>
                  {item["Billing Name"] &&
                    item["Billing Name"].includes(" ") &&
                    `${item["Billing Name"]
                      .split(" ")[0]
                      .charAt(0)
                      .toUpperCase()}${item["Billing Name"]
                      .split(" ")[0]
                      .slice(1)
                      .toLowerCase()} ${item["Billing Name"]
                      .split(" ")[1]
                      .charAt(0)
                      .toUpperCase()}`}
                </td>
                <td>{item["Billing Province"]}</td>
                <td>{item["Lineitem name"].split("- ")[1].split(" ")[0]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="paginate-div">
        <button onClick={handlePrevPageClick}>Prev</button>
        <span>
          Page {page} of {totalPages}
        </span>
        <button onClick={handleNextPageClick}>Next</button>
      </div>
    </div>
  );
}

export default Dashboard;
