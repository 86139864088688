import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Form, Button, Alert } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Logo from "./BCLogo.png";
import "./Login.css";

const Login = () => {
  const [credentials, setCredentials] = useState({
    email: "",
  });
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    setCredentials({
      ...credentials,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(
        "https://su-ledger-backend.herokuapp.com/login",
        credentials
      );
      localStorage.setItem("token", response.data.token);
      navigate("/dashboard");
      toast.success("Login successful");
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <Container className="login-container">
      <div className="login-child-1">
        <img src={Logo} alt="logo" className="login-logo" />
        <h2>Entrant Ledger</h2>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="email" className="email-form">
            <Form.Label>Email: </Form.Label>
            <Form.Control
              type="text"
              name="email"
              value={credentials.email}
              onChange={handleInputChange}
              className="login-input"
            />
          </Form.Group>
          <div className="login-child-2">
            <Button variant="primary" type="submit" className="login-btn">
              Login
            </Button>
          </div>
        </Form>
        <ToastContainer />
      </div>
    </Container>
  );
};

export default Login;
